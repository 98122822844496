import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="Footer">
      <p>2024 | &copy; Road Pave Civils and Construction </p>
      <p>88 Monument Rd, Nimrod Park, Kempton Park, 1620</p>
    </div>
  );
};

export default Footer;